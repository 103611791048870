import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/SEO"
import Link from "../components/Link"
import NakedList from "../components/NakedList"
import wordmark from "../assets/vectors/wordmark.svg"

const StyledDiv = styled("div")`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
`

const StyledImg = styled("img")`
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 90vw;
`

const Wordmark = ({ ...props }) => (
    <StyledDiv>
        <StyledImg src={wordmark} alt="SHIN GUARD" {...props} />
    </StyledDiv>
)

const DateLabel = styled("span")`
    flex: none;
`

const Venue = styled("span")`
    color: #888;
`

const Column = styled("div")`
    display: flex;
    flex-direction: column;
    text-align: right;
`

const NoFlexColumn = styled(Column)`
    flex: none;
`

const StyledTableRow = styled("li")`
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    & > *:not(:last-child) {
        padding-right: 1rem;
    }
`

const StyledLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    & > *:not(:last-child) {
        padding-right: 1rem;
    }
`

const TicketsLink = styled(StyledLink)`
    padding: 0;
`

const StyledList = styled(props => <NakedList as="ol" {...props} />)`
    width: 100%;
    max-width: 450px;
    margin: 5vh auto 0;
    padding-bottom: 20vh;
`

const ShowsTable = ({ shows }) => {
    const Row = show => (
        <React.Fragment>
            <NoFlexColumn>
                <DateLabel>{show.formattedDate}</DateLabel>
                {show.ticketsURL && (
                    <span>
                        <TicketsLink href={show.ticketsURL || "/"}>
                            tickets
                        </TicketsLink>
                    </span>
                )}
            </NoFlexColumn>
            <Column>
                <span>{show.location || ""}</span>
                <Venue>{show.venue || ""}</Venue>
            </Column>
        </React.Fragment>
    )
    return (
        <StyledList>
            {shows.map(show => (
                <StyledTableRow key={show.id}>
                    {show.ticketsURL && show.date > new Date() ? (
                        <StyledLink href={show.ticketsURL || "/"} noDecoration>
                            <Row {...show} />
                        </StyledLink>
                    ) : (
                        <Row {...show} />
                    )}
                </StyledTableRow>
            ))}
        </StyledList>
    )
}

const tabBarItems = [
    { label: "Upcoming", value: "future" },
    { label: "Past", value: "past" }
]

const TabBar = styled("div")`
    display: flex;
    justify-content: center;
    margin-top: 10vh;
`

const Tab = styled("button")`
    border: 0;
    border-bottom: ${props => (props.isSelected ? "2px solid #fff" : "0")};
    padding: 1rem 1rem
        ${props => (props.isSelected ? "calc(1rem - 2px)" : "1rem")} 1rem;
    background-color: transparent;
    color: #fff;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
`

const ShowsTabs = ({ items, didSelectTab }) => {
    const [selectedTab, setSelectedTab] = useState(items[0])
    function onSelectTab(item) {
        setSelectedTab(item)
        didSelectTab(item)
    }
    return (
        <TabBar>
            {items &&
                items.map(item => (
                    <Tab
                        key={item.value}
                        isSelected={selectedTab.value === item.value}
                        onClick={() => onSelectTab(item)}
                    >
                        {item.label}
                    </Tab>
                ))}
        </TabBar>
    )
}

const ShowsWrapper = styled("div")`
    width: 100%;
    max-width: 450px;
    height: 100vh;
`

const Shows = ({ data }) => {
    const showsData = data.allShows.edges
        .map(edge => edge.node.data)
        .map(data => ({
            id: data.ID,
            location: data.Location,
            venue: data.Venue,
            date: data.Date,
            formattedDate: data.formattedDate,
            otherBands: data.Other_Bands,
            ticketsURL: data.Ticket_Link
        }))
    const allShows = {
        future: showsData
            .filter(show => new Date(show.date) > new Date())
            .reverse(),
        past: showsData.filter(show => new Date(show.date) < new Date())
    }
    const [selectedShows, setSelectedShows] = useState(allShows.future)
    return (
        <ShowsWrapper id="shows">
            <ShowsTabs
                items={tabBarItems}
                didSelectTab={item => setSelectedShows(allShows[item.value])}
            />
            <ShowsTable shows={selectedShows} />
        </ShowsWrapper>
    )
}

const IndexPage = ({ data }) => {
    return (
        <React.Fragment>
            <SEO />
            <Wordmark />
            <Shows data={data} />
        </React.Fragment>
    )
}

export const query = graphql`
    query GetShows2 {
        allShows: allAirtable(
            filter: { table: { eq: "Shows" } }
            sort: { fields: data___Date, order: DESC }
        ) {
            edges {
                node {
                    data {
                        ID
                        Location
                        Venue
                        Date
                        formattedDate: Date(formatString: "DD MMM YY")
                        Other_Bands
                        Ticket_Link
                    }
                }
            }
        }
    }
`

export default IndexPage
